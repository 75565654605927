
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { user$ } from '@gz/auth';
import 'isomorphic-fetch';

import { BaseService } from '../services/BaseService';

@Component({
	components: {},
})
export default class HomePage extends Vue {
	localUser$ = user$;
	user = { name: 'User', username: '' };
	isOpen?: boolean;
	showSupplierIcon = false;
	clicks = 0;

	baseService = new BaseService();

	port = '9000';
	tenantId = 'daf84c0a-e8bf-4a54-910c-90a081a561db';
	clientId = 'e2dec960-7dda-4496-a328-066a9b5eff38';
	clientSecret = 'fbh8Q~sv8boUKvX.f1h9.xdVP1cfe2p_dalWIckz';
	scopes = 'User.Read.All, profile, email, openid';
	redirectUri = window.location.href.includes('localhost')
		? 'http://localhost:9000/customersupport'
		: window.location.href.includes('staging')
		? 'https://greenzonegateway-staging.azurewebsites.net/customersupport'
		: 'https://greenzonegateway.azurewebsites.net/customersupport';

	authorityHost = 'https://login.microsoftonline.com/daf84c0a-e8bf-4a54-910c-90a081a561db';

	ToggleTheme() {
		// eslint-disable-next-line no-undef
		let vals: NodeListOf<Element>;

		if (this.$vuetify.theme.dark) {
			var elems = new Set([
				...document.querySelectorAll('.v-application.theme--dark'),
				...document.querySelectorAll('.layout-container .theme--dark'),
			]);

			elems.forEach((element) => {
				element.classList.remove('theme--dark');
				element.classList.add('theme--light');
			});
		} else {
			var elems2 = new Set([
				...document.querySelectorAll('.v-application.theme--light'),
				...document.querySelectorAll('.layout-container .theme--light'),
			]);

			elems2.forEach((element) => {
				element.classList.remove('theme--light');
				element.classList.add('theme--dark');
			});
		}

		this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
	}

	ShowIcon() {
		if (this.clicks === 4) {
			this.showSupplierIcon = true;
		} else if (this.clicks <= 4) {
			this.clicks++;
		}
	}

	GoToSupplier() {
		return ' http://' + window.location.host + '/supplieraccounts';
	}

	ChangeTitle(data: any, path: string) {
		var val = document.getElementById('root-project')?.getElementsByTagName('span')[0];
		(val as HTMLElement).innerText = data.currentTarget.innerText.toLowerCase();

		if (this.$vuetify.breakpoint.mobile) {
			this.ShowSidebar();
		}
	}
	StateChange(data: any) {
		const el = document.getElementById('root-navigation-drawer');

		if (!data) {
			(el as HTMLElement).style.width = '240px';
		} else {
			(el as HTMLElement).style.width = 'auto';
		}
	}

	ShowSidebar() {
		const element = document.getElementsByClassName('v-navigation-drawer')[0];
		const classList = element.classList;
		const exists = [...classList].find((classes) => classes.includes('v-navigation-drawer--close'));

		if (exists) {
			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.remove('v-navigation-drawer--close');

			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.remove('v-navigation-drawer--is-mobile');

			(document.getElementsByClassName('v-navigation-drawer')[0] as HTMLElement).style.transform =
				'translateX(0)';
		} else {
			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.add('v-navigation-drawer--close');

			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.add('v-navigation-drawer--is-mobile');

			(document.getElementsByClassName('v-navigation-drawer')[0] as HTMLElement).style.transform =
				'translateX(-100%)';
		}
	}

	async created() {
		this.localUser$.subscribe(async (user: any) => {
			if (user) {
				this.user = user;
			}
		});
	}

	destroyed() {
		this.localUser$.unsubscribe();
	}
}
