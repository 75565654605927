
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import HomePage from './views/HomePage.vue';

@Component({
	components: {
		HomePage,
	},
})
export default class extends Vue {
	created() {
		const theme = localStorage.getItem('dark_theme');
		if (theme) {
			if (theme === 'true') {
				this.$vuetify.theme.dark = true;
			} else {
				this.$vuetify.theme.dark = false;
			}
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.$vuetify.theme.dark = true;
			localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
		}
	}
}
