/* eslint-disable max-len */
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount('#app');

const vueLifecycles = singleSpaVue({
	Vue,
	appOptions: {
		render(h: any) {
			return h(App, {
				props: {
					// single-spa props are available on the "this" object. Forward them to your component as needed.
					// https://single-spa.js.org/docs/building-applications#lifecyle-props
					// if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
					/*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
				},
			});
		},
		router,
		vuetify,
	},
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
