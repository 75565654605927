var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            absolute: "",
            "expand-on-hover": !_vm.$vuetify.breakpoint.mobile,
            "mini-variant": "true",
          },
          on: { "update:mini-variant": _vm.StateChange },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { staticClass: "px-2" },
                [
                  _c(
                    "v-list-item-avatar",
                    { staticClass: "mx-0", on: { click: _vm.ShowIcon } },
                    [
                      _c("v-img", {
                        attrs: { src: require(`@/assets/gzlogo.png`) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.ToggleTheme()
                        },
                      },
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.theme.dark
                              ? "mdi-weather-sunny"
                              : "mdi-weather-night"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-tabs",
            { attrs: { vertical: "" } },
            [
              _c(
                "v-tab",
                {
                  attrs: { to: "/customersupport" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.ChangeTitle.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "pr-1", attrs: { left: "" } }, [
                    _vm._v(" mdi-account "),
                  ]),
                  _vm._v(" Support "),
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  attrs: { to: "/customers" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.ChangeTitle.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "pr-1", attrs: { left: "" } }, [
                    _vm._v(" mdi-lock "),
                  ]),
                  _vm._v(" Customers "),
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  attrs: { to: "/accounts" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.ChangeTitle.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "pr-1", attrs: { left: "" } }, [
                    _vm._v(" mdi-access-point "),
                  ]),
                  _vm._v(" Accounts "),
                ],
                1
              ),
              _vm.showSupplierIcon
                ? _c(
                    "v-tab",
                    {
                      attrs: { to: "/supplieraccounts" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.ChangeTitle.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pr-1", attrs: { left: "" } },
                        [_vm._v(" mdi-truck ")]
                      ),
                      _vm._v(" Supplier Accounts "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-footer",
            { staticStyle: { width: "400px", "padding-left": "0" } },
            [
              _c(
                "v-tab",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://greenzone.freshservice.com/support/tickets/new",
                  },
                },
                [
                  _c("v-icon", { staticClass: "pr-1", attrs: { left: "" } }, [
                    _vm._v(" mdi-account-question "),
                  ]),
                  _vm._v(" Log An Issue "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }